import {TextField, IconButton, Typography} from '@mui/material';
import {TFunction} from 'i18next';
import {MRT_Cell, MRT_Column, MRT_Row} from 'material-react-table';
import React, {useEffect} from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'; // 用於格式化的圖標

export default function EditTableStringArrayColumn({
  t,
  cell,
  row,
  column,
  defaultValue,
  placeHolder,
}: {
  t: TFunction<'translation', undefined>;
  cell: MRT_Cell<any, unknown>;
  row: MRT_Row<any>;
  column: MRT_Column<any, unknown>;
  defaultValue?: string[];
  placeHolder?: string;
}) {
  const value = cell.getValue();

  const [editValue, setEditValue] = React.useState(() =>
    value && Array.isArray(value)
      ? value.join('\n')
      : defaultValue?.join('\n') || '',
  );

  useEffect(() => {
    row._valuesCache[column.id] = editValue.split('\n').filter(Boolean);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditValue(event.target.value);

    try {
      const parsedValue = event.target.value.split('\n').filter(Boolean);
      row._valuesCache[column.id] = parsedValue;
    } catch (error) {
      console.error('Invalid string array:', error);
    }
  };

  // 格式化為每行一個字符串
  const handlePrettyFormat = () => {
    const formattedValue = editValue
      .split('\n')
      .map(line => line.trim())
      .filter(Boolean)
      .join('\n');
    setEditValue(formattedValue);
  };

  return (
    <>
      <Typography
        mt={1}
        mb={-2}
        sx={{
          color: 'gray',
          fontSize: '1rem',
        }}
      >
        {column.columnDef.header}
      </Typography>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <TextField
          multiline
          minRows={4}
          maxRows={8}
          fullWidth
          value={editValue}
          placeholder={placeHolder}
          onChange={handleChange}
          helperText={t('validateStringArray')}
          error={(() => {
            try {
              editValue.split('\n').filter(Boolean); // 驗證是否能分割為字符串數組
              return false;
            } catch {
              return true;
            }
          })()}
        />
        <IconButton
          onClick={handlePrettyFormat}
          color='primary'
          aria-label='format-string-array'
          style={{marginLeft: '8px'}}
        >
          <FormatAlignLeftIcon />
        </IconButton>
      </div>
    </>
  );
}
