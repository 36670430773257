import {AxiosResponse} from 'axios';
import {
  GetUserListDto,
  PatchUserDto,
  PostUserDto,
  PutUserSetRoleDto,
  UserDto,
} from './dto/user';
import callApi from './index';

export const getUserMe = ():
  | Promise<AxiosResponse<{data: UserDto}, any>>
  | undefined => {
  return callApi('get', `/user/me`);
};

export const getUserMeAccount = () => {
  return callApi('get', `/user/me/account`);
};

export const createUser = (
  data: PostUserDto,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('post', `/user`, data);
};

export const getUserList = (data: GetUserListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/user/list`, options);
};

export const getUserById = (
  id: string,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('get', `/user/${id}`);
};

export const updateUser = (id: string, data: PatchUserDto) => {
  return callApi('patch', `/user/${id}`, data);
};

export const updateUserMe = (
  data: any,
): Promise<AxiosResponse<{data: number}, any>> | undefined => {
  return callApi('patch', `/user/me`, data);
};

export const updateUserSetRole = (
  id: string,
  data: PutUserSetRoleDto,
): Promise<AxiosResponse<{data: number}, any>> | undefined => {
  return callApi('put', `/user/${id}/role/set`, data);
};

export const deleteUser = (id: string) => {
  return callApi('delete', `/user/${id}`);
};
