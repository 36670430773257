import {AxiosResponse} from 'axios';
import {GetPlanListDto, PatchPlanDto, PlanDto, PostPlanDto} from './dto/plan';
import callApi from './index';

export const createPlan = (data: PostPlanDto) => {
  return callApi('post', `/plan`, data);
};

export const getPlanList = (data: GetPlanListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/plan/list`, options);
};

export const getPlanPublicList = ():
  | Promise<AxiosResponse<{items: PlanDto[]}, any>>
  | undefined => {
  return callApi('get', `/plan/public/list`);
};

export const updatePlan = (id: string, data: PatchPlanDto) => {
  return callApi('patch', `/plan/${id}`, data);
};

export const deletePlan = (id: string) => {
  return callApi('delete', `/plan/${id}`);
};
