import React from 'react';
import {TFunction} from 'i18next';
import {Alert, AlertTitle} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const NoPermissionAlert = ({t}: {t: TFunction<'translation', undefined>}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
      }}
    >
      <Alert
        severity='error'
        icon={<LockIcon fontSize='large' />}
        style={{maxWidth: '600px', width: '100%'}}
      >
        <AlertTitle>
          {t('dashboardAlertNotPermissionTitle', 'Access Denied')}
        </AlertTitle>
        {t(
          'dashboardAlertNotPermission',
          'You do not have permission to access this section.',
        )}
      </Alert>
    </div>
  );
};

export default NoPermissionAlert;
