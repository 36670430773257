import React, {useState, useEffect} from 'react';
import {Box, Typography, Button, TextField, Paper} from '@mui/material';
import {loadScript} from '@paypal/paypal-js';

const SubscriptionPage: React.FC = () => {
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    loadScript({
      clientId: 'YOUR_PAYPAL_CLIENT_ID',
      vault: 'true', // 必須啟用以支持訂閱
    })
      .then(() => {
        setPaypalLoaded(true);
      })
      .catch(err => {
        console.error('PayPal SDK 加載失敗', err);
      });
  }, []);

  const handleSubscribe = () => {
    if (!email) {
      setError('請輸入有效的電子郵件地址');
      return;
    }

    setError('');
    if (paypalLoaded) {
      // 初始化 PayPal Buttons
      (window as any).paypal
        .Buttons({
          createSubscription: function (data: any, actions: any) {
            return actions.subscription.create({
              plan_id: 'YOUR_PLAN_ID', // 替換為你的 PayPal 訂閱計劃 ID
            });
          },
          onApprove: function (data: any) {
            alert(`訂閱成功！訂閱 ID：${data.subscriptionID}`);
          },
          onError: function (err: any) {
            console.error('訂閱失敗', err);
            alert('訂閱失敗，請稍後再試');
          },
        })
        .render('#paypal-button-container');
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Paper elevation={3} sx={{p: 4, maxWidth: 400, width: '100%'}}>
        <Typography variant='h5' sx={{mb: 2, textAlign: 'center'}}>
          訂閱我們的服務
        </Typography>
        <TextField
          fullWidth
          label='電子郵件地址'
          variant='outlined'
          value={email}
          onChange={e => setEmail(e.target.value)}
          sx={{mb: 2}}
          error={!!error}
          helperText={error}
        />
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSubscribe}
          sx={{mb: 2}}
        >
          開始訂閱
        </Button>
        <Box id='paypal-button-container' sx={{mt: 2}} />
      </Paper>
    </Box>
  );
};

export default SubscriptionPage;
