import {Box, Chip, Typography} from '@mui/material';
import {TFunction} from 'i18next';
import {MRT_Cell, MRT_Column, MRT_Row} from 'material-react-table';
import React, {useEffect, useState} from 'react';
import {getPermissionList} from '../../api/permission';
import {RolePermissionDto} from '../../api/dto/rolePermission';

export default function EditTablePermissionColumn({
  t,
  cell,
  row,
  column,
}: {
  t: TFunction<'translation', undefined>;
  cell: MRT_Cell<any, unknown>;
  row: MRT_Row<any>;
  column: MRT_Column<any, unknown>;
}) {
  const [permissionList, setPermissionList] = useState<
    {scope: string; action: string}[]
  >([]);
  const [selectedPermissions, setSelectedPermissions] = useState<
    {scope: string; action: string}[]
  >([]);

  const value = cell.getValue() as RolePermissionDto[];
  const initialPermissions = value
    ? value.map(perm => ({
        scope: perm.platformPermission?.scope,
        action: perm.platformPermission?.action,
      }))
    : [];

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await getPermissionList({
        limit: 1000,
      });
      if (response?.data.items) {
        setPermissionList(response.data.items);

        setSelectedPermissions(initialPermissions as any);
        for (const i of initialPermissions) {
          setPermissionList(prevList =>
            prevList.filter(
              perm => !(perm.scope === i.scope && perm.action === i.action),
            ),
          );
        }
      }
      row._valuesCache[column.id] = initialPermissions;
    };
    fetchPermissions();
  }, []);

  const handleAddPermission = (scope: string, action: string) => {
    const alreadySelected = selectedPermissions.some(
      perm => perm.scope === scope && perm.action === action,
    );
    if (!alreadySelected) {
      const updatedPermissions = [...selectedPermissions, {scope, action}];
      setSelectedPermissions(updatedPermissions);
      row._valuesCache[column.id] = updatedPermissions;

      setPermissionList(prevList =>
        prevList.filter(
          perm => !(perm.scope === scope && perm.action === action),
        ),
      );
    }
  };

  const handleDeletePermission = (scope: string, action: string) => {
    const updatedPermissions = selectedPermissions.filter(
      perm => !(perm.scope === scope && perm.action === action),
    );
    setSelectedPermissions(updatedPermissions);
    row._valuesCache[column.id] = updatedPermissions;

    setPermissionList(prevList => [...prevList, {scope, action}]);
  };

  return (
    <div>
      <Typography
        mt={2}
        mb={1}
        sx={{
          color: 'gray',
          fontSize: '1rem',
        }}
      >
        {column.columnDef.header}
      </Typography>
      <Box display='flex' flexWrap='wrap' gap={1} mt={2} mb={2}>
        {selectedPermissions.map((perm, index) => (
          <Chip
            key={index}
            label={`${perm.scope}:${perm.action}`}
            onDelete={() => handleDeletePermission(perm.scope, perm.action)}
            color='primary'
          />
        ))}
      </Box>

      <Box mt={2}>
        {permissionList.length > 0 && (
          <Box display='flex' flexWrap='wrap' gap={1}>
            {permissionList.map((perm, index) => (
              <Chip
                key={index}
                label={`${perm.scope}:${perm.action}`}
                onClick={() => handleAddPermission(perm.scope, perm.action)}
                style={{cursor: 'pointer'}}
                variant='outlined'
              />
            ))}
          </Box>
        )}
      </Box>
    </div>
  );
}
