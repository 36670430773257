import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import {mainListItems, secondaryListItems, thirdListItems} from './listItems';
import {getUserMe} from '../api/user';
import {HttpStatusCode} from 'axios';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open}) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard({element}: {element: React.ReactElement}) {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  React.useEffect(() => {
    getUserMe()?.then(d => {
      if (d.status !== HttpStatusCode.Ok) {
        navigate('/sign-in');
      }
      localStorage.setItem('groupId', d?.data?.data?.groupId);
    });
  }, [navigate]);

  React.useEffect(() => {
    if (localeI18n) i18n.changeLanguage(localeI18n);
  }, [localeI18n, i18n]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <AppBar position='absolute' open={open}>
          <Toolbar sx={{pr: '24px'}}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && {display: 'none'}),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              sx={{flexGrow: 1}}
            >
              {t('dashboardTitle')}
            </Typography>
            <LanguageSelector i18n={i18n} />
            <IconButton color='inherit'>
              <Badge badgeContent={0} color='secondary'>
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color='inherit' onClick={handleLogout}>
              <LogoutTwoToneIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant='permanent' open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <Typography marginRight='auto'>
              <Link href='/'>
                <img
                  src='/logo-symbol.png'
                  alt='brand-logo'
                  width={45}
                  height={35}
                />
              </Link>
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component='nav'>
            {mainListItems(t)}
            <Divider sx={{my: 1}} />
            {secondaryListItems(t)}
            <Divider sx={{my: 1}} />
            {thirdListItems(t)}
          </List>
        </Drawer>
        {element}
      </Box>
    </ThemeProvider>
  );
}
