import * as React from 'react';
import {PaletteMode} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {LightMode, DarkMode, ArrowDownward} from '@mui/icons-material';
import AppAppBar from '../components/AppAppBar';
import LogoCollection from '../components/LogoCollection';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import getLPTheme from '../components/getLPTheme';
import Hero from '../components/Hero';
import {Fade, Zoom, Slide} from 'react-awesome-reveal'; // 使用 react-awesome-reveal 提供的動畫效果

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode(prev => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{bgcolor: 'background.default'}}>
        {/* Hero Section Scroll Down Icon */}
        <Box sx={{textAlign: 'center', mt: 2}}>
          <ArrowDownward
            sx={{
              fontSize: 40,
              color: 'primary.main',
              animation: 'bounce 2s infinite',
            }}
          />
        </Box>

        {/* Features Section with Zoom Effect */}
        <Zoom>
          <Features />
        </Zoom>
        <Divider />

        {/* Logo Collection Section with Slide Effect */}
        {/* <Slide direction='left'>
          <LogoCollection />
        </Slide> */}
        <Divider />

        {/* Highlights Section with Fade Effect */}
        <Fade>
          <Highlights />
        </Fade>
        <Divider />

        {/* Pricing Section with Zoom Effect */}
        <Zoom>
          <Pricing />
        </Zoom>
        <Divider />

        {/* FAQ Section with Slide Effect */}
        <Slide direction='up'>
          <FAQ />
        </Slide>
        <Divider />

        <Fade duration={2000}>
          <Footer />
        </Fade>
      </Box>

      {/* Custom Global Animation Keyframes */}
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-10px);
            }
            60% {
              transform: translateY(-5px);
            }
          }
        `}
      </style>
    </ThemeProvider>
  );
}
