import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useTranslation} from 'react-i18next';
import {postSignIn} from '../api/auth';
import LoginIcon from '@mui/icons-material/Login';
import {
  Alert,
  AlertColor,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import {HttpStatusCode} from 'axios';
import {ALERT_HIDE_DURATION} from '../constant';
import {getPlatformPublicList} from '../api/platform';
import {GoogleLoginButton} from '../components/GoogleLogin';
import Copyright from '../components/Copyright';
import './SignIn.css';
import {PlatformDto} from '../api/dto/platform';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // Customize primary color
    },
    background: {
      default: '#f4f6f8', // Light background color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration: number;
}

export default function SignIn() {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [email, setEmail] = React.useState('');
  const [cancelUrl, setCancelUrl] = React.useState('/');
  const [returnUrl, setReturnUrl] = React.useState('/dashboard');
  const [policyUrl, setPolicyUrl] = React.useState('/policy');
  const [searchPlatformId, setSearchPlatformId] = React.useState<string>('');
  const [disabledPlatformId, setDisabledPlatformId] =
    React.useState<boolean>(false);
  const [password, setPassword] = React.useState('');
  const [platformId, setPlatformId] = React.useState('');
  const [platformList, setPlatformList] = React.useState<PlatformDto[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, setState] = React.useState<State>({
    open: false,
    autoHideDuration: ALERT_HIDE_DURATION,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;
  const {autoHideDuration} = state;

  const handleClick = (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: autoHideDuration || ALERT_HIDE_DURATION,
    });
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const successFunc = (signinData: {data: any}) => {
    localStorage.setItem('token', signinData.data.data.token);
    localStorage.setItem('platformId', platformId);
    handleClick('success', t('signinSuccess'));

    let resultUrl: string | URL = returnUrl;
    try {
      const url = new URL(returnUrl);
      const urlParams = new URLSearchParams(url.search);
      urlParams.set('token', signinData.data.data.token);
      urlParams.set('event', 'signin');
      url.search = urlParams.toString();
      resultUrl = url;
    } catch (e) {}

    window.location.replace(resultUrl);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const signin = await postSignIn({
      email,
      password,
      platformId,
    });

    if (signin?.status === HttpStatusCode.Ok) {
      successFunc(signin);
    } else {
      handleClick('error', signin?.data.message);
    }
  };

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  React.useEffect(() => {
    const fetchPlatformList = async () => {
      setIsLoading(true);

      getPlatformPublicList({limit: 1000})
        ?.then(d => {
          const platformList = d?.data ? d?.data.items.map(item => item) : [];
          setPlatformList(platformList);

          if (platformList.length > 0) {
            setIsLoading(false);
          }
        })
        .catch(e => {
          handleClick('error', e.message || 'Failed to load platforms');
        });
    };

    fetchPlatformList();
  }, []);

  React.useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);
    const platformId = urlSearch.get('platformId');
    const returnUrlParam = urlSearch.get('returnUrl');
    const cancelUrlParam = urlSearch.get('cancelUrl');

    if (platformId) {
      setPolicyUrl('');
      platformList.forEach(platform => {
        if (platform.id === platformId) {
          const {returnUrl = [], cancelUrl = []} = platform;

          if (returnUrlParam && !returnUrl.includes(returnUrlParam)) {
            setIsLoading(true);
            handleClick(
              'error',
              t('signinReturnUrlError'),
              ALERT_HIDE_DURATION * 100,
            );
            return;
          }

          if (cancelUrlParam && !cancelUrl.includes(cancelUrlParam)) {
            setIsLoading(true);
            handleClick(
              'error',
              t('signinCancelUrlError'),
              ALERT_HIDE_DURATION * 100,
            );
            return;
          }

          setPlatformId(platform.id);
          localStorage.setItem('platformId', platformId);

          setReturnUrl(returnUrlParam || returnUrl[0] || '/dashboard');
          setCancelUrl(cancelUrlParam || cancelUrl[0] || '/');
          setPolicyUrl(platform.policyUrl ?? '');
        }
      });

      setSearchPlatformId(platformId);
      setDisabledPlatformId(true);
    }
  }, [platformList, t]);

  return (
    <ThemeProvider theme={theme}>
      {/* <AppAppBar mode={mode} toggleColorMode={toggleColorMode} /> */}
      <div className='background-animation'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>

      <Button
        color='primary'
        variant='contained'
        size='small'
        component='a'
        rel='noopener'
        onClick={() => window.location.replace(cancelUrl)}
      >
        <KeyboardReturnIcon />
      </Button>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 3,
            p: 4,
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {t('signin')}
          </Typography>
          <Box component='form' onSubmit={handleSubmit} sx={{mt: 1}}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label={t('signinEmail')}
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={({target: {value}}) => {
                setEmail(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label={t('signinPassword')}
              type='password'
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={({target: {value}}) => {
                setPassword(value);
              }}
            />
            <FormControl required fullWidth disabled={disabledPlatformId}>
              <InputLabel id='platform-simple-select-label'>
                {t('signinPlatformName')}
              </InputLabel>
              <Select
                labelId='platform-simple-select-label'
                id='platform-simple-select'
                value={searchPlatformId || platformId}
                label={t('signinPlatformName')}
                onChange={v => {
                  setPlatformId(v.target.value);
                }}
              >
                {platformList
                  .filter(p => (disabledPlatformId ? p : !!p.isActive))
                  .map(g => (
                    <MenuItem key={g.id} value={g.id}>
                      {g.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label={t('rememberMe')}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{mt: 3, mb: 2, py: 1.5, borderRadius: '20px'}}
              endIcon={<LoginIcon />}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : t('signin')}
            </Button>
            <GoogleLoginButton
              t={t}
              platformId={platformId}
              successFunc={successFunc}
              handleClick={handleClick}
            />
            <Grid container justifyContent='space-between' sx={{mt: 2}}>
              <Link href={`sign-up${window.location.search}`} variant='body2'>
                {t('signinNoAccountSignup')}
              </Link>
              {policyUrl ? (
                <Link href={policyUrl} variant='body2'>
                  {t('privacyPolicy')}
                </Link>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
