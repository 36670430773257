import {Box, Chip, Typography} from '@mui/material';
import {TFunction} from 'i18next';
import {MRT_Cell, MRT_Column, MRT_Row} from 'material-react-table';
import React, {useEffect, useState} from 'react';
import {getRoleList} from '../../api/role';
import {UserRoleDto} from '../../api/dto/userRole';

export default function EditTableRoleColumn({
  t,
  cell,
  row,
  column,
}: {
  t: TFunction<'translation', undefined>;
  cell: MRT_Cell<any, unknown>;
  row: MRT_Row<any>;
  column: MRT_Column<any, unknown>;
}) {
  const [roleList, setRoleList] = useState<{name: string; id: string}[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<
    {name: string; id: string}[]
  >([]);
  const value = cell.getValue() as UserRoleDto[];
  useEffect(() => {
    const fetchRoles = async () => {
      const response = await getRoleList({
        limit: 1000,
      });

      const initialSelectedRoles =
        (value &&
          value?.map(userRole => ({
            name: userRole?.role?.name,
            id: userRole.role?.id,
          }))) ||
        [];
      setSelectedRoles(initialSelectedRoles);

      if (response?.data.items) {
        const availableRoles = response.data.items.filter(
          role =>
            !initialSelectedRoles.some(selected => selected.name === role.name),
        );
        setRoleList(availableRoles);
      }
    };
    fetchRoles();
  }, []);

  const handleAddRole = (data: {id: string; name: string}) => {
    const {id, name} = data;
    const alreadySelected = selectedRoles.some(role => role.name === name);

    if (!alreadySelected) {
      const updatedRoles = [...selectedRoles, {name, id}];
      setSelectedRoles(updatedRoles);
      row._valuesCache[column.id] = updatedRoles;
      setRoleList(prevList => prevList.filter(role => role.name !== name));
    }
  };

  const handleDeleteRole = (data: {id: string; name: string}) => {
    const {id, name} = data;
    const updatedRoles = selectedRoles.filter(role => role.name !== name);
    setSelectedRoles(updatedRoles);
    row._valuesCache[column.id] = updatedRoles;
    setRoleList(prevList => [...prevList, {name, id}]);
  };

  return (
    <div>
      <Typography
        mt={2}
        mb={1}
        sx={{
          color: 'gray',
          fontSize: '1rem',
        }}
      >
        {column.columnDef.header}
      </Typography>
      <Box display='flex' flexWrap='wrap' gap={1} mt={2} mb={2}>
        {selectedRoles?.map((perm, index) => (
          <Chip
            key={index}
            label={perm.name}
            onDelete={() => handleDeleteRole({name: perm.name, id: perm.id})}
            color='primary'
          />
        ))}
      </Box>

      <Box mt={2}>
        {roleList.length > 0 && (
          <Box display='flex' flexWrap='wrap' gap={1}>
            {roleList.map((perm, index) => (
              <Chip
                key={index}
                label={perm.name}
                onClick={() => handleAddRole({name: perm.name, id: perm.id})}
                style={{cursor: 'pointer'}}
                variant='outlined'
              />
            ))}
          </Box>
        )}
      </Box>
    </div>
  );
}
