import * as React from 'react';
import * as THREE from 'three';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import FastForwardTwoToneIcon from '@mui/icons-material/FastForwardTwoTone';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';

const items = (t: TFunction<'translation', undefined>) => [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: t('highlightAdaptable'),
    description: t('highlightAdaptableDescription'),
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: t('highlightHighFrequency'),
    description: t('highlightHighFrequencyDescription'),
  },
  {
    icon: <FastForwardTwoToneIcon />,
    title: t('highlightHighFast'),
    description: t('highlightHighFastDescription'),
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: t('highlightHighUserExperience'),
    description: t('highlightHighUserExperienceDescription'),
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: t('highlightHighSupport'),
    description: t('highlightHighSupportDescription'),
  },
];

const ThreeBackground: React.FC = () => {
  const mountRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    );
    const renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.domElement.style.position = 'absolute';
    renderer.domElement.style.top = '0';
    renderer.domElement.style.left = '0';
    renderer.domElement.style.zIndex = '-1'; // 背景層級

    mountRef.current?.appendChild(renderer.domElement);

    // 粒子系統
    const particleCount = 1000;
    const particlesGeometry = new THREE.BufferGeometry();
    const particlesMaterial = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 0.05,
      transparent: true,
      opacity: 0.7,
    });

    const positions = new Float32Array(particleCount * 3);
    for (let i = 0; i < particleCount; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 100;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 100;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 100;
    }
    particlesGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(positions, 3),
    );

    const particles = new THREE.Points(particlesGeometry, particlesMaterial);
    scene.add(particles);

    camera.position.z = 5;

    const animate = () => {
      requestAnimationFrame(animate);

      // 粒子旋轉
      particles.rotation.x += 0.001;
      particles.rotation.y += 0.001;

      renderer.render(scene, camera);
    };
    animate();

    const handleResize = () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default function Highlights() {
  const {t} = useTranslation();
  return (
    <Box
      id='highlights'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        color: 'white',
        bgcolor: 'transparent', // 背景透明，讓粒子顯示
        position: 'relative',
        overflow: 'hidden', // 防止超出內容溢出
      }}
    >
      <ThreeBackground />
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: {xs: 3, sm: 6},
        }}
      >
        <Box
          sx={{
            width: {sm: '100%', md: '60%'},
            textAlign: {sm: 'left', md: 'center'},
          }}
        >
          <Typography component='h2' variant='h4'>
            {t('highlights')}
          </Typography>
          <Typography variant='body1' sx={{color: 'grey.400'}}>
            {t('highlightDescription')}
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items(t).map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction='column'
                color='inherit'
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{opacity: '50%'}}>{item.icon}</Box>
                <div>
                  <Typography fontWeight='medium' gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant='body2' sx={{color: 'grey.400'}}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
