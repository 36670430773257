import React, {useState, useEffect} from 'react';
import {Link, PaletteMode} from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import ToggleColorMode from './ToggleColorMode';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import {useNavigate} from 'react-router-dom';

const logoStyle = {
  width: '60px',
  height: 'auto',
  cursor: 'pointer',
};

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function AppAppBar({mode, toggleColorMode}: AppAppBarProps) {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userAvatar, setUserAvatar] = useState<string | null>(null);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    if (!sectionElement) {
      navigate('/');
    }
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({behavior: 'smooth'});
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    // 檢查是否有登入 Token
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      setUserAvatar('/path-to-avatar.jpg'); // 修改為實際的用戶頭像 URL
    }
  }, []);

  const handleAvatarClick = () => {
    navigate('/dashboard');
  };

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth='lg'>
          <Toolbar
            variant='regular'
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <Link href='/'>
                <img
                  src={'/logo-symbol.png'}
                  style={logoStyle}
                  alt='logo of zzztech'
                />
              </Link>
              <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                <MenuItem
                  onClick={() => scrollToSection('features')}
                  sx={{py: '6px', px: '12px'}}
                >
                  <Typography variant='body2' color='text.primary'>
                    {t('features')}
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => scrollToSection('testimonials')}
                  sx={{py: '6px', px: '12px'}}
                >
                  <Typography variant='body2' color='text.primary'>
                    {t('testimonials')}
                  </Typography>
                </MenuItem> */}
                <MenuItem
                  onClick={() => scrollToSection('highlights')}
                  sx={{py: '6px', px: '12px'}}
                >
                  <Typography variant='body2' color='text.primary'>
                    {t('highlights')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('pricing')}
                  sx={{py: '6px', px: '12px'}}
                >
                  <Typography variant='body2' color='text.primary'>
                    {t('pricing')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('faq')}
                  sx={{py: '6px', px: '12px'}}
                >
                  <Typography variant='body2' color='text.primary'>
                    {t('faq')}
                  </Typography>
                </MenuItem>
              </Box>
            </Box>

            <Box
              sx={{
                display: {xs: 'none', md: 'flex'},
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              <LanguageSelector i18n={i18n}></LanguageSelector>
              {isLoggedIn ? (
                <Avatar
                  src={userAvatar || undefined}
                  onClick={handleAvatarClick}
                  sx={{
                    cursor: 'pointer',
                    width: 32,
                    height: 32,
                  }}
                />
              ) : (
                <>
                  <Button
                    color='primary'
                    variant='text'
                    size='small'
                    component='a'
                    href='/sign-in'
                  >
                    {t('signin')}
                  </Button>
                  <Button
                    color='primary'
                    variant='contained'
                    size='small'
                    component='a'
                    href='/sign-up'
                  >
                    {t('signup')}
                  </Button>
                </>
              )}
            </Box>

            <Box sx={{display: {sm: '', md: 'none'}}}>
              <LanguageSelector i18n={i18n}></LanguageSelector>

              <Button
                variant='text'
                color='primary'
                aria-label='menu'
                onClick={toggleDrawer(true)}
                sx={{minWidth: '30px', p: '4px'}}
              >
                <MenuIcon />
              </Button>

              <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode
                      mode={mode}
                      toggleColorMode={toggleColorMode}
                    />
                  </Box>
                  <MenuItem onClick={() => scrollToSection('features')}>
                    {t('features')}
                  </MenuItem>
                  {/* <MenuItem onClick={() => scrollToSection('testimonials')}>
                    {t('testimonials')}
                  </MenuItem> */}
                  <MenuItem onClick={() => scrollToSection('highlights')}>
                    {t('highlights')}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('pricing')}>
                    {t('pricing')}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('faq')}>
                    {t('faq')}
                  </MenuItem>
                  <Divider />

                  {isLoggedIn ? (
                    <MenuItem>
                      <Avatar
                        src={userAvatar || undefined}
                        onClick={handleAvatarClick}
                        sx={{
                          cursor: 'pointer',
                          width: 32,
                          height: 32,
                        }}
                      />
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem>
                        <Button
                          color='primary'
                          variant='contained'
                          sx={{width: '100%'}}
                          component='a'
                          href='/sign-up'
                        >
                          {t('signup')}
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color='primary'
                          variant='text'
                          sx={{width: '100%'}}
                          component='a'
                          href='/sign-in'
                        >
                          {t('signin')}
                        </Button>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
