import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import {Container, Typography, Box} from '@mui/material';

export default function APIDocumentationPage() {
  return (
    <Container maxWidth='lg' sx={{marginTop: 4}}>
      <Typography variant='h4' gutterBottom>
        API Documentation
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        Explore the API endpoints and try them out directly.
      </Typography>
      <Box sx={{marginTop: 4}}>
        <SwaggerUI url='http://localhost:7032/swagger/index.html' />
      </Box>
    </Container>
  );
}
